import React from "react";

import Button from "./Button";

const HeroCallout: React.FC<{ heading: string; text: string; href: string }> =
  ({ heading, text, href }) => {
    return (
      <section key={href} className="max-w-6xl px-10 md:px-6 lg:px-16">
        <Button href={href} className="w-full">
          {heading}
        </Button>
        <p className="mt-2 mb-8 md:mb-10">{text}</p>
      </section>
    );
  };

export default HeroCallout;
