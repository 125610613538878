import React from "react";
import { HiBell } from "react-icons/hi";
import { GatsbyImage } from "gatsby-plugin-image";

import Button from "../../../components/Button";
import LongLocalDate from "../../../components/LongLocalDate";

const getButtonText = (auction): string => {
  if (auction.isLive) return "Auction is Live";
  if (auction.isComingSoon) return "Coming Soon";
  return "View Details";
};

const AuctionItem: React.FC<{
  auction: Queries.AuctionsQuery["active_auctions"]["nodes"][0];
}> = ({ auction }) => (
  <section className="auction w-full">
    <header className="flex flex-col justify-between items-center w-full bg-clhbid-orange py-8">
      <div className="flex flex-col lg:flex-row justify-between items-start lg:items-end max-w-screen-xl w-full px-3">
        <h2 className="text-3xl">{auction.name}</h2>
        <div className="flex">
          <h3 className="text-2xl">
            <LongLocalDate dateAsISOString={auction.auctionDate} />
          </h3>
          <h3 className="text-2xl pl-16">{auction.province}</h3>
        </div>
      </div>
    </header>
    <div className="w-full flex flex-col items-center py-8">
      <div className="max-w-screen-xl w-full px-3 flex flex-col lg:flex-row">
        <div className="lg:w-64 lg:h-48">
          {auction.localImage &&
            (!auction.isComingSoon ? (
              <a
                className="flex-none w-full h-auto lg:w-64 lg:h-48 pb-8 lg:pb-0"
                href={auction.preferredUrl}
              >
                <GatsbyImage
                  image={auction.localImage.childImageSharp.gatsbyImageData}
                  className="pb-8 w-full h-auto lg:pb-0 lg:w-64 lg:h-48"
                  alt={`Picture of ${auction.name}`}
                />
              </a>
            ) : (
              <div>
                <GatsbyImage
                  image={auction.localImage.childImageSharp.gatsbyImageData}
                  className="pb-8 w-full h-auto lg:pb-0 lg:w-64 lg:h-48"
                  alt={`Picture of ${auction.name}`}
                />
              </div>
            ))}
        </div>
        <div
          className={`auction-description flex-1 lg:px-24`}
          dangerouslySetInnerHTML={{ __html: auction.description }}
        />
        <div className="flex flex-col gap-2">
          <Button
            external
            href={auction.preferredUrl}
            disabled={auction.isComingSoon}
            className="w-full whitespace-nowrap flex items-center justify-center"
          >
            {getButtonText(auction)}
          </Button>
          {auction.isReminderAvailable && (
            <Button
              family="white"
              href={auction.reminderUrl}
              state={{ modal: true, noScroll: true }}
              className="w-full whitespace-nowrap flex items-center justify-center"
            >
              <HiBell className="inline w-6 mr-2" />
              Set Reminder
            </Button>
          )}
        </div>
      </div>
    </div>
  </section>
);

export default AuctionItem;
