import React from "react";

import Button from "./Button";
import LocalDate from "./LocalDate";

const PastAuction: React.FC<
  Queries.AuctionsQuery["past_auctions"]["nodes"][0]
> = ({ auctionDate, name, preferredUrl }) => (
  <section className="past-auction max-w-screen-xl w-full py-8 lg:py-12 flex flex-col lg:flex-row justify-between w-full border-b last:border-b-0">
    <h2 className="pb-8 lg:pb-0">
      {name} -{" "}
      <LocalDate
        dateAsISOString={auctionDate}
        formatOptions={{
          month: "long",
          day: "numeric",
          year: "numeric",
        }}
      />
    </h2>

    <Button href={preferredUrl} className="flex-none self-start">
      View Details
    </Button>
  </section>
);

export default PastAuction;
