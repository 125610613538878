import React from "react";

import useAuctions from "../../hooks/use-auctions";

import PageSection from "../../components/PageSection";
import PastAuctions from "../../components/PastAuctions";
import CenteredPageSection from "../../components/CenteredPageSection";

import AuctionItem from "./AuctionItem";

const Auctions: React.FC = () => {
  const {
    auctions: { active_auctions, past_auctions },
  } = useAuctions();

  return (
    <div>
      <CenteredPageSection id="current-auctions">
        <h1 className="uppercase text-center text-2xl lg:text-4xl">
          Upcoming Auctions
        </h1>
      </CenteredPageSection>
      <PageSection id="upcoming-auctions-list">
        {active_auctions.length > 0 ? (
          active_auctions.map((auction) => (
            <AuctionItem key={auction.auctionId} auction={auction} />
          ))
        ) : (
          <h2 className="pb-8 text-gray-700">
            <em>There are currently no upcoming auctions.</em>
          </h2>
        )}
      </PageSection>
      <CenteredPageSection
        id="past-auctions"
        fullWidthClassNames="bg-clhbid-orange"
      >
        <h1 className="text-2xl lg:text-4xl">Past Sales</h1>
      </CenteredPageSection>
      <CenteredPageSection>
        <PastAuctions pastAuctions={past_auctions} maxAuctions={5} />
      </CenteredPageSection>
    </div>
  );
};

export default Auctions;
