export const HomePage = {
  url: "/",
  title: "Selling Farm Land, It's All We Do",
  description:
    "Clhbid is a leading-edge online auction platform that provides farm land and rural land for sale in Alberta. You can easily get details of land for sale in Alberta at CLHbid.com. Visit our website now!",
};

export const ContactPage = {
  url: "/contact",
  title: "Contact Us",
  description:
    "At CLHbid, we have the right platform supported by a team with extensive knowledge in the areas of agriculture, legal and accounting. We know there is a big difference between selling land and maximizing value for our Sellers. Contact CLHbid and let our team maximize the value for your lifetime of work.",
};

export const PastAuctionsPage = {
  url: "/past-auctions",
  title: "Past Auctions",
  description:
    "You only sell your land once, get it right and trust our count on our history of successful and on-time sales.",
};

export const TeamPage = {
  url: "/about-clhbid/clhbid-team",
  title: "The CLHBid.com Team",
  description:
    "Clhbid is a leading-edge online auction platform that provides farm land and rural land for sale in Alberta. You can easily get details of land for sale in Alberta at CLHbid.com. Visit our website now!",
};

export const FaqsPage = {
  url: "/about-clhbid/frequently-asked-questions",
  title: "Frequently Asked Questions",
  description:
    "Clhbid is a leading-edge online auction platform that provides farm land and rural land for sale in Alberta. You can easily get details of land for sale in Alberta at CLHbid.com. Visit our website now!",
};

export const AssembleLandPage = {
  url: "/assemble-land",
  title: "Assemble Land | CLHBid.com",
  description:
    "Putting Buyers and Sellers Together With Outstanding Results. Join with a neighbour to create a package to fit the Buyer's needs.",
};

export const SellerPersuasionPage = {
  url: "/sell-with-clhbid",
  title:
    "Sell with Western Canada’s proven, leading-edge online tender platform",
  description:
    "You only sell your land once, make sure you get it right. Sell with Western Canada’s proven, leading-edge online tender platform.",
};

export const TestimonialVideoPage = {
  url: "/testimonial-video",
  title: "Testimonial Video",
  description:
    "Watch the Hon. Ted Menzies describing why he chose CLHbid.com to sell his 45 year old family farm.",
};

export const TestimonialsPage = {
  url: "/why-clhbid/clhbid-testimonials",
  title: "Testimonials",
  description:
    "Clhbid is a leading-edge online auction platform that provides farm land and rural land for sale in Alberta. You can easily get details of land for sale in Alberta at CLHbid.com. Visit our website now!",
};

export const LoginPage = {
  url: `/login`,
  title: "Login",
  description: "Login to bid on auctions and update your profile.",
};

export const RegisterPage = {
  url: `/register`,
  title: "Register",
  description: "Register to bid on auctions.",
};
