import React from "react";

const BrowserOnly: React.FC<{ minWidth: number; children: React.ReactNode }> =
  ({ minWidth, children }) => {
    return (
      <>
        {typeof document !== "undefined" &&
          document.documentElement.clientWidth >= minWidth &&
          children}
      </>
    );
  };

export default BrowserOnly;
