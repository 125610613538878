import React from "react";

import Button from "./Button";
import PastAuction from "./PastAuction";

export const MAX_AUCTIONS = 5;

/**
 * Return all auctions, unless the maxAuctions is set, in which case return no more than that number of auctions.
 */
function getRecentAuctions(
  pastAuctions: Queries.AuctionsQuery["past_auctions"]["nodes"],
  maxAuctions: number
) {
  return maxAuctions ? pastAuctions.slice(0, maxAuctions) : pastAuctions;
}

export const PastAuctionsButton: React.FC<{
  pastAuctions: Queries.AuctionsQuery["past_auctions"]["nodes"];
  maxAuctions?: number;
}> = ({ pastAuctions, maxAuctions }) => {
  if (!maxAuctions || pastAuctions.length <= maxAuctions) {
    return null;
  }
  return (
    <section className="max-w-screen-xl w-full py-8 lg:py-12 flex flex-col lg:flex-row justify-between w-full border-b last:border-b-0">
      <Button href="/past-auctions" className="past-auctions-button">
        More past sales...
      </Button>
    </section>
  );
};

const PastAuctions: React.FC<{
  maxAuctions?: number;
  pastAuctions: Queries.AuctionsQuery["past_auctions"]["nodes"];
}> = ({ pastAuctions, maxAuctions }) => {
  const recent_auctions = getRecentAuctions(pastAuctions, maxAuctions);
  const past_auctions = recent_auctions.map((past_auction) => (
    <PastAuction key={past_auction.auctionId} {...past_auction} />
  ));

  return (
    <div>
      {past_auctions}
      <PastAuctionsButton
        pastAuctions={pastAuctions}
        maxAuctions={maxAuctions}
      />
    </div>
  );
};

export default PastAuctions;
