import React from "react";
import { graphql, Link, PageProps } from "gatsby";
import { GatsbyImage } from "gatsby-plugin-image";

import routes from "../../clhbid-routes";

import { HomePage as seo } from "../helpers/seo";

import SEO from "../components/SEO";
import Button from "../components/Button";
import HeroCallout from "../components/HeroCallout";
import BrowserOnly from "../components/BrowserOnly";
import AuctionNotice from "../components/AuctionNotice";
import CenteredPageSection from "../components/CenteredPageSection";

import Auctions from "../sections/Auctions";

import useAuctions from "../hooks/use-auctions";

import downArrow from "../images/angle-double-down-solid.svg";

// Declare Video Poster Inline Image
const VIDEO_POSTER =
  "data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAALABQDASIAAhEBAxEB/8QAFwABAQEBAAAAAAAAAAAAAAAABAABAv/EABYBAQEBAAAAAAAAAAAAAAAAAAEDBP/aAAwDAQACEAMQAAABVyXM00wJP//EABcQAQEBAQAAAAAAAAAAAAAAAAABEzL/2gAIAQEAAQUC2rZqvSv/xAAWEQEBAQAAAAAAAAAAAAAAAAAAARH/2gAIAQMBAT8BZH//xAAXEQADAQAAAAAAAAAAAAAAAAAAAhJR/9oACAECAQE/AbbSm0//xAAYEAADAQEAAAAAAAAAAAAAAAAAATEQMv/aAAgBAQAGPwKERyh7/8QAGhAAAgMBAQAAAAAAAAAAAAAAAAERITFBof/aAAgBAQABPyGLihz0oF5B3pVwj//aAAwDAQACAAMAAAAQSz//xAAYEQACAwAAAAAAAAAAAAAAAAAAAUFhkf/aAAgBAwEBPxBqChYf/8QAFhEBAQEAAAAAAAAAAAAAAAAAAGGR/9oACAECAQE/EAo1/8QAHBABAAICAwEAAAAAAAAAAAAAAQARITFBUYGR/9oACAEBAAE/ECoFWmZdbalN7SPP5zADQK+MIKFiZJ0wJ//Z";

/******************************************************************************
 *
 *                             !!! ATTENTION !!!
 *
 * THESE VIDEOS ARE CACHED IN `sw.js` TO PREVENT LARGE NETWORK DOWNLOAD COSTS.
 * MAKE SURE YOU UPDATE `URLS_TO_CACHE` in `sw.js` WHEN YOU ADD NEW VIDEOS.
 *
 *****************************************************************************/
// Declare Video URLs
const BACKGROUND_VIDEO_MP4 =
  "https://storage.googleapis.com/clhbid-prod/CLHbkg-1080p.mp4";
const BACKGROUND_VIDEO_WEBM =
  "https://storage.googleapis.com/clhbid-prod/CLHbkg-1080p.webm";

const HomePage: React.FC<PageProps<Queries.HomePageQuery>> = ({ data }) => {
  const { isError, isStale, isFetching } = useAuctions();

  return (
    <>
      <SEO title={seo.title} description={seo.description} />

      <section className="hero relative w-full">
        <div
          className="absolute inset-0 z-0"
          style={{ filter: "brightness(80%)" }}
        >
          <GatsbyImage
            image={data.mobileHeroBackground.childImageSharp.gatsbyImageData}
            loading="eager"
            className="w-full h-full object-cover lg:hidden"
            alt="CLHbid.com movie background. A golden field with a brown grazing horse and cobalt blue sky."
          />
        </div>
        <div className="hidden lg:block absolute inset-0 z-10">
          <BrowserOnly minWidth={1024}>
            <video
              id="hero_movie"
              className="w-full h-full object-cover"
              poster={VIDEO_POSTER}
              autoPlay
              muted
              loop
            >
              <source src={BACKGROUND_VIDEO_WEBM} type="video/webm" />
              <source src={BACKGROUND_VIDEO_MP4} type="video/mp4" />
              <p>Your browser cannot play the provided video file.</p>
            </video>
          </BrowserOnly>
        </div>
        <div className="relative z-20 flex flex-col align-center justify-center w-full h-144 pt-4 text-white">
          <h1 className="pb-12 font-medium md:px-16 md:pb-16 text-3xl md:text-4xl text-center text-shadow">
            Selling Farm Land, <br className="md:hidden" /> It&apos;s All We Do
          </h1>

          <div className="flex flex-col md:flex-row text-center justify-center">
            <HeroCallout
              heading="Buy Land"
              text="See our upcoming sales"
              href="/#current-auctions"
            />
            <HeroCallout
              heading="Sell Land"
              text="The CLHbid.com advantage"
              href="/sell-with-clhbid/"
            />
          </div>
        </div>
        <div className="relative w-full z-20">
          <div>
            <svg
              style={{ transform: "rotateX(180deg) translate(0, -3px)" }}
              xmlns="http://www.w3.org/2000/svg"
              version="1.1"
              fill="#f8b332"
              width="100%"
              height="60"
              viewBox="0 0 4.66666 0.333331"
              preserveAspectRatio="none"
            >
              <path d="M-0 0.333331l4.66666 0 0 -3.93701e-006 -2.33333 0 -2.33333 0 0 3.93701e-006zm0 -0.333331l4.66666 0 0 0.166661 -4.66666 0 0 -0.166661zm4.66666 0.332618l0 -0.165953 -4.66666 0 0 0.165953 1.16162 -0.0826181 1.17171 -0.0833228 1.17171 0.0833228 1.16162 0.0826181z"></path>
            </svg>
          </div>
          <div className="absolute top-0 w-full flex items-center justify-center">
            <div className="bg-white rounded-full w-20 h-20 flex items-center justify-center">
              <img alt="" src={downArrow} className="h-8" />
            </div>
          </div>
        </div>
      </section>

      <CenteredPageSection
        fullWidthClassNames="bg-clhbid-orange"
        classNames="flex flex-col lg:flex-row lg:justify-center"
      >
        <div className="flex-1 pb-8 lg:pb-0 lg:flex lg:flex-col mx-8 lg:mx-16 lg:text-right lg:items-end lg:max-w-md">
          <p className="text-xl lg:max-w-xs">
            <strong>
              You only sell your land once, make sure you get it right!
            </strong>
          </p>

          <p>
            In agriculture, for any job you need the right piece of equipment
            and the right operator. At CLHbid.com we are just that &mdash; the
            right platform with the right team of professionals to advise on all
            matters to ensure you receive top value from the sale of your
            agricultural land.
          </p>

          <Button
            href={routes.register()}
            family="white"
            external
            className="block mx-auto w-fit lg:inline-block lg:mx-0 lg:w-auto"
          >
            Create A Free Account
          </Button>
        </div>
        <div className="flex-1 mx-8 lg:mx-16 lg:max-w-md">
          <p>
            Watch the Hon. Ted Menzies describing why he chose CLHbid.com to
            sell his 45 year old family farm.
          </p>
          <Link to="/testimonial-video/" state={{ modal: true }}>
            <GatsbyImage
              image={data.videoThumbnail.childImageSharp.gatsbyImageData}
              alt="Watch the Hon. Ted Menzies describing why he chose CLHbid.com to sell his 45 year old family farm."
            />
          </Link>
        </div>
      </CenteredPageSection>
      <div>
        <AuctionNotice
          isError={isError}
          isFetching={isFetching}
          isStale={isStale}
        />
        <Auctions />
      </div>
    </>
  );
};

export const query = graphql`
  query HomePage {
    mobileHeroBackground: file(
      relativePath: { eq: "hero-mobile-background.jpg" }
    ) {
      childImageSharp {
        gatsbyImageData(quality: 70, layout: FULL_WIDTH)
      }
    }
    videoThumbnail: file(relativePath: { eq: "home-video-thumbnail.jpg" }) {
      childImageSharp {
        gatsbyImageData(width: 935, quality: 20, layout: CONSTRAINED)
      }
    }
  }
`;

export default HomePage;
