import React from "react";
import LocalDate from "./LocalDate";

const LongLocalDate: React.FC<{
  dateAsISOString: string;
}> = ({ dateAsISOString }) => (
  <>
    <LocalDate
      dateAsISOString={dateAsISOString}
      formatOptions={{
        day: "numeric",
        weekday: "long",
        month: "long",
        year: "numeric",
      }}
    />{" "}
    at{" "}
    <LocalDate
      dateAsISOString={dateAsISOString}
      formatOptions={{
        hour: "numeric",
        minute: "numeric",
        timeZoneName: "short",
      }}
    />
  </>
);

export default LongLocalDate;
