import React from "react";

export const status = {
  STALE: "Auctions are out-of-date.",
  FETCHING: "Updating...",
  ERROR:
    "ERROR: Unable to update auctions! Refresh the page and check your internet connection.",
};
/**
 * Create Auction Messages
 *
 * This works by essentially checking whether or not the array item generates a value
 * that isn't falsy (`false`, `null`, `undefined`, `0`, or `NaN`).
 *
 * @see http://www.devign.me/javascript-tip-remove-falsy-items-out-of-an-array
 */
function createAuctionMessages(isError, isFetching, isStale) {
  return [
    isError ? status.ERROR : null,
    isStale && isFetching ? [status.STALE, status.FETCHING].join(" ") : null,
    isStale && !isFetching ? status.STALE : null,
  ].filter(Boolean);
}

const AuctionNotice: React.FC<{
  isError: boolean;
  isFetching: boolean;
  isStale: boolean;
}> = ({ isError, isFetching, isStale }) => {
  const auction_msgs = createAuctionMessages(isError, isFetching, isStale);

  if (auction_msgs.length === 0) return null;

  return (
    <div className="fixed bottom-0 left-0 m-2 z-50 bg-black-50 px-4 py-2 text-white mb-0">
      <ul>
        {auction_msgs.map((msg, i) => (
          <li key={`auction-notice-${i}`}>{msg}</li>
        ))}
      </ul>
    </div>
  );
};

export default AuctionNotice;
